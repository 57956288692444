export const ServicesList = [
  {
    id: "1",
    category: "media",
    title: "2D Animation",
    shortDescription:
      "Custom 2D animated videos, logos, and motion graphics to bring your brand to life.",
    longDetails:
      "Our 2D animation services bring your brand's story to life through engaging, handcrafted animations. From explainer videos to logo animations, our team of skilled artists and animators create custom 2D animations that capture your audience's attention. We take the time to understand your brand's unique personality and messaging, ensuring that every frame is tailored to your specific needs. With expertise in both traditional and digital techniques, we can create a wide range of styles, from classic cartoons to modern motion graphics. Whether you need a simple animated logo or a complex explainer video, our team will work closely with you to bring your vision to life. Disclaimer: Service Disclaimer: Our 2D animation services are designed to provide high-quality visual content. However, we cannot guarantee the success of your project or the accuracy of the animation in representing your brand or message.",
    disclaimer:
      "Service Disclaimer: Our 2D animation services are designed to provide high-quality visual content. However, we cannot guarantee the success of your project or the accuracy of the animation in representing your brand or message.",
    variants: [
      {
        id: "1-1",
        duration: "1 min",
        price: "10000",
        timeDuration: "2-5 days",
        preDuration: "2 days",
      },
      {
        id: "1-2",
        duration: "3 min",
        price: "30000",
        timeDuration: "1-3 weeks",
        preDuration: "2 days",
      },
      {
        id: "1-3",
        duration: "5 min",
        price: "50000 ",
        timeDuration: "1-3 weeks",
        preDuration: "2 days",
      },
    ],
    duration: "",
    price: "",
    timeDuration: "",
    preDuration: "",
  },
  {
    id: "2",
    category: "media",
    title: "3D Animation",
    shortDescription:
      "High-quality 3D animated videos, models, and visual effects for engaging storytelling.",
    longDescription: `Our 3D animation services offer unparalleled visual storytelling capabilities, transporting your audience to new dimensions. With expertise in modeling, texturing, lighting, and animation, our team crafts stunning 3D visuals that captivate and inspire. From product demos to cinematic experiences, we push the boundaries of what's possible in 3D animation. Our team collaborates closely with you to ensure that every detail, from character design to environmental effects, aligns with your brand's unique vision.`,
    disclaimer:
      "Our 3D animation services are designed to provide photorealistic visual content. However, we cannot guarantee the accuracy of the animation in representing real-world environments or objects.",
    variants: [
      {
        id: "2-1",
        duration: "1 min",
        price: "15000",
        timeDuration: "3-6 days",
        preDuration: "2 days",
      },
      {
        id: "2-2",
        duration: "3 min",
        price: "45000",
        timeDuration: "2-6 weeks",
        preDuration: "2 days",
      },
      {
        id: "2-3",
        duration: "5 min",
        price: "75000 ",
        timeDuration: "1-3 weeks",
        preDuration: "2 days",
      },
    ],
    duration: "",
    price: "",
    timeDuration: "",
    preDuration: "",
  },
  {
    id: "3",
    category: "media",
    title: "Sound Editing",
    shortDescription:
      "Professional audio editing services for crystal-clear sound in videos, films, and podcasts.",
    longDescription: `Our sound editing services transform your audio, elevating it from good to exceptional. With a keen ear for detail, our expert sound editors refine every aspect of your audio, from noise reduction to music composition. We ensure that dialogue is crisp, sound effects are precise, and music is perfectly balanced. Whether you need podcast editing, voiceover work, or sound design for film and video, our team delivers high-quality audio that engages and immerses your audience.`,
    disclaimer:
      "Our sound editing services are designed to enhance audio quality. However, we cannot guarantee the removal of all noise or imperfections.",
    variants: [],
    duration: "1 day",
    price: "15000",
    timeDuration: "1-3 weeks",
    preDuration: "2 days",
  },
  {
    id: "4",
    category: "media",
    title: "Infographics Animation",
    shortDescription:
      "Engaging animated infographics to simplify complex information and data.",
    longDescription:
      "Our infographics animation services bring complex information to life through engaging, animated visuals. By combining data visualization with compelling animation, we simplify intricate concepts, making them easy to understand and remember. Our team of expert designers and animators craft custom infographics that capture your audience's attention, communicating key messages and insights with clarity and precision. Whether you need to explain a product, service, or process, our infographics animation services help you connect with your audience and drive results.",
    disclaimer:
      "Our infographics animation services are designed to simplify complex information. However, we cannot guarantee the accuracy of the information presented.",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-3 weeks",
    preDuration: "2 days",
  },
  {
    id: "5",
    category: "media",
    title: "VFX Green Screen to 3D Animation",
    shortDescription:
      "Transform green screen footage into immersive 3D animations.",
    longDescription:
      "Our green screen to 3D animation services transforms your live-action footage into immersive, 3D environments. By combining green screen technology with expert 3D animation, we create seamless interactions between actors and virtual elements. Our team of skilled artists and animators craft custom 3D models, textures, and lighting, ensuring a believable and engaging visual experience. Whether you need to create a fictional world, extend a set, or enhance a product demonstration, our green screen to 3D animation services help you push the boundaries of visual storytelling.",
    disclaimer:
      "Our green screen to 3D animation services are designed to combine live-action footage with 3D environments. However, we cannot guarantee seamless integration or accuracy.",
    variants: [],
    duration: "1 day",
    price: "50000",
    timeDuration: "1-5 weeks",
    preDuration: "2 days",
  },
  {
    id: "6",
    category: "media",
    title: "Video Animation with 2D effects",
    shortDescription:
      "Combine live-action video with 2D animated elements for unique visuals.",
    longDescription:
      "Our video animation with 2D effects services bring your brand's story to life through engaging, animated visuals. By combining live-action video with custom 2D animation, we create unique, captivating narratives that resonate with your audience. Our team of expert designers and animators craft custom 2D elements, from motion graphics to character animation, ensuring a cohesive visual language that aligns with your brand's identity. Whether you need an explainer video, social media content, or broadcast commercial, our video animation with 2D effects services help you connect with your audience and drive results.",
    disclaimer:
      "Our video animation with 2D effects services are designed to enhance visual content. However, we cannot guarantee the accuracy or realism of the 2D effects.",
    variants: [],
    duration: "1 day",
    price: "40000",
    timeDuration: "1-5 weeks",
    preDuration: "2 days",
  },
  {
    id: "7",
    category: "media",
    title: "Grading, Digital Intermediate, and Post-Production",
    shortDescription:
      "Expert colour grading, DI, and post-production services for polished video output.",
    longDescription:
      "Our grading, digital intermediate, and post-production services ensure that your final product is polished and visually stunning. Our team of expert colorists, editors, and visual effects artists collaborate closely with you to refine every detail, from color grading to sound design. We utilize the latest software and technologies to deliver high-quality results, enhancing the overall narrative and emotional impact of your film, video, or commercial. Our services include color grading, visual effects, sound design, editing, and final mastering, ensuring a cohesive and engaging visual experience that resonates with your audience.",
    disclaimer:
      "Our grading, digital intermediate, and post-production services are designed to enhance visual and audio quality. However, we cannot guarantee the final product will meet your creative vision.",
    variants: [
      {
        id: "7-1",
        duration: "NA",
        price: "2500",
        timeDuration: "5-7 days",
        preDuration: "2 days",
      },
      {
        id: "7-2",
        duration: "15 sec",
        price: "5000",
        timeDuration: "5-7 days",
        preDuration: "2 days",
      },
      {
        id: "7-3",
        duration: "30 sec",
        price: "10000 ",
        timeDuration: "5-7 days",
        preDuration: "2 days",
      },
    ],
    duration: "",
    price: "",
    timeDuration: "",
    preDuration: "",
  },
  {
    id: "8",
    category: "it",
    title: "Database Management System (Cloud-Based Server): ",
    shortDescription:
      "Secure, scalable cloud-based database solutions for efficient data management.",
    longDescription:
      "Our database management system services provide secure, scalable, and efficient data management solutions, leveraging the latest cloud-based technologies. Our team of expert database administrators and developers design, implement, and maintain customized database systems, ensuring data integrity, security, and performance. We utilize leading cloud providers, such as AWS or Azure, to deliver flexible and cost-effective solutions, scalable to meet the evolving needs of your business. Our services include database design, development, migration, and management, ensuring seamless data integration and accessibility across your organization.",
    disclaimer:
      "Our database management system services are designed to provide secure and efficient data storage. However, we cannot guarantee data security or availability.",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "9",
    category: "it",
    title: "Cloud Storage Subscription",
    shortDescription:
      "Reliable cloud storage plans for secure file storage and collaboration.",
    longDescription:
      ":  Our cloud storage subscription services provide secure, scalable, and efficient data storage solutions, ensuring seamless access to your files from anywhere, at any time. Our team of expert cloud engineers collaborates with leading cloud providers to deliver customized storage solutions, tailored to meet the evolving needs of your business. With automatic backup, versioning, and disaster recovery, our cloud storage services ensure business continuity and minimize data loss risks. Our services include cloud storage setup, configuration, migration, and management, ensuring effortless data sharing, collaboration, and access control across your organization.",
    disclaimer:
      "Our cloud storage subscription services are designed to provide secure and efficient data storage. However, we cannot guarantee data security or availability.",
    variants: [],
    duration: "1 day",
    price: "32000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "10",
    category: "it",
    title: "Firmware Development for Switches",
    shortDescription:
      "Custom firmware development for network switches to enhance performance and security.",
    longDescription:
      "Our firmware development services for switches deliver customized, high-performance solutions, enhancing network reliability, security, and efficiency. Our team of expert firmware developers collaborates closely with you to understand your specific requirements, designing and implementing tailored firmware solutions that meet your unique needs. With expertise in network protocols, device drivers, and embedded systems, we ensure seamless integration, compatibility, and performance. Our services include firmware design, development, testing, and deployment, ensuring optimal network performance, security, and scalability.",
    disclaimer:
      "Our firmware development services are designed to provide customized solutions. However, we cannot guarantee compatibility or functionality.",
    variants: [],
    duration: "1 day",
    price: "20000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "11",
    category: "it",
    title: "Upgrade Firmware Program for Switches",
    shortDescription:
      "Expert firmware upgrades for switches to ensure latest features and security patches.",
    longDescription:
      "Our upgrade firmware program services ensure your network switches stay up-to-date, secure, and efficient, leveraging the latest features and technologies. Our team of expert firmware developers collaborates closely with you to plan, design, and implement customized upgrade solutions, minimizing downtime and ensuring seamless integration. With expertise in network protocols, device drivers, and embedded systems, we ensure optimal performance, security, and compatibility. Our services include firmware versioning, testing, and deployment, ensuring your network infrastructure remains current, secure, and high-performing.",
    disclaimer:
      "Our firmware upgrade services are designed to provide updated functionality. However, we cannot guarantee compatibility or functionality.",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "12",
    category: "it",
    title: "Backup Firmware Program for Switches",
    shortDescription:
      "Secure backup firmware solutions to prevent data loss and ensure business continuity.",
    longDescription:
      "Our backup firmware program services provide an additional layer of protection, ensuring business continuity and minimizing downtime risks. Our team of expert firmware developers collaborates closely with you to design and implement customized backup solutions, automatically storing firmware versions and configurations. With secure storage, versioning, and disaster recovery, our backup firmware services ensure rapid recovery, minimizing network disruption and data loss. Our services include backup firmware design, implementation, and management, ensuring peace of mind and network resilience.",
    disclaimer:
      "Our backup firmware services are designed to provide disaster recovery solutions. However, we cannot guarantee data recovery or system functionality.",
    variants: [],
    duration: "1 day",
    price: "20000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "13",
    category: "it",
    title: "SOM (System Object Model) Program (Principle Test Scale): ",
    shortDescription:
      "Comprehensive system testing and validation for reliable performance.",
    longDescription:
      "Our SOM program services ensure your system's architecture is robust, scalable, and maintainable, utilizing a principle test scale approach. Our team of expert system architects and engineers collaborate closely with you to design and implement a tailored SOM, aligning with your business needs and goals. We utilize industry-leading frameworks and methodologies to ensure a modular, flexible, and efficient system design, enabling seamless integration and extension. Our services include SOM design, implementation, testing, and documentation, ensuring a solid foundation for your system's development and growth.",
    disclaimer:
      "Our SOM program services are designed to provide a robust system architecture. However, we cannot guarantee system performance or scalability.",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "14",
    category: "it",
    title: "Regression Testing",
    shortDescription:
      "Thorough testing to ensure software updates don't introduce new bugs or issues.",
    longDescription:
      "Our regression testing services ensure your system or application remains stable and functions as expected after changes, updates, or maintenance. Our team of expert testers collaborates closely with you to design and execute comprehensive regression testing plans, identifying and addressing potential issues before they impact users. With expertise in test automation, data-driven testing, and exploratory testing, we ensure thorough coverage and efficient testing cycles. Our services include test planning, test case development, test execution, and defect reporting, ensuring your system's reliability and performance.",
    disclaimer:
      ": Our regression testing services are designed to identify defects. However, we cannot guarantee the identification of all defects. ",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "15",
    category: "it",
    title: "Alpha Acceptance Testing",
    shortDescription:
      "Early-stage testing to identify and fix critical issues before launch.",
    longDescription:
      "Our alpha acceptance testing services provide early-stage testing and feedback, ensuring your system or application meets key requirements and user expectations. Our team of expert testers collaborates closely with you to design and execute tailored alpha testing plans, identifying and addressing critical issues before wider deployment. With expertise in user experience, usability, and functional testing, we ensure your system meets the needs of your target audience. Our services include test planning, test case development, test execution, and feedback reporting, ensuring your system's quality and user satisfaction.",
    disclaimer:
      "Our alpha acceptance testing services are designed to provide early-stage feedback. However, we cannot guarantee the identification of all issues.",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
  {
    id: "16",
    category: "it",
    title: "Beta Acceptance Testing",
    shortDescription:
      "Final-stage testing with real users to ensure smooth launch and user satisfaction.",
    longDescription:
      "Our beta acceptance testing services provide real-world testing and feedback, ensuring your system or application is ready for launch and meets user expectations. Our team of expert testers collaborates closely with you to design and execute comprehensive beta testing plans, identifying and addressing final issues before public release. With expertise in user experience, usability, and functional testing, we ensure your system meets the needs of your target audience. Our services include test planning, test case development, test execution, and feedback reporting, ensuring your system's quality, reliability, and user satisfaction.",
    disclaimer:
      "Our beta acceptance testing services are designed to provide real-world feedback. However, we cannot guarantee the identification of all issues.",
    variants: [],
    duration: "1 day",
    price: "12000",
    timeDuration: "1-2 weeks",
    preDuration: "2 days",
  },
];

export default ServicesList;
