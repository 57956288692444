import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

// Create the PDF Document
const Invoice = (props) => {
    const { invoiceData, cartTotalValue, invoiceNumber, activeUserData } = props;
    console.log("invoiceData: ", invoiceData);
    console.log("invoiceNumber: ", invoiceNumber);
    console.log("activeUserData: ", activeUserData);

    const percentage = (percent, total) => {
        console.log("percentage: ", ((percent / 100) * total).toFixed(2));
        return ((percent / 100) * total).toFixed(2)
    }

    const num = "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" ");
    const tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

    const number2words = (n) => {
        if (n < 20) return num[n];
        var digit = n % 10;
        if (n < 100) return tens[~~(n / 10) - 2] + (digit ? "-" + num[digit] : "");
        if (n < 1000) return num[~~(n / 100)] + " hundred" + (n % 100 == 0 ? "" : " " + number2words(n % 100));
        return number2words(~~(n / 1000)) + " thousand" + (n % 1000 != 0 ? " " + number2words(n % 1000) : "");
    }

    let cgst = +percentage(9, cartTotalValue);
    let sgst = +percentage(9, cartTotalValue);
    let grandTotal = +(cgst + sgst + cartTotalValue);

    const renderList = () => {
        return invoiceData.map((element, index) => {
            return (
                <View style={styles.tableRow} key={index}>
                    <Text style={styles.tableCol}>{index + 1}</Text>
                    <Text style={styles.tableCol}>{element?.title}</Text>
                    {/* <Text style={styles.tableCol}>998612</Text> */}
                    <Text style={styles.tableCol}>{element?.quantity}</Text>
                    <Text style={styles.tableCol}>{element?.price}</Text>
                    <Text style={styles.tableCol}>{element?.price}</Text>
                </View>
            )
        })
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.header}>Tax Invoice</Text>

                <View style={styles.section}>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={styles.bold}>Akmitt Innovations Private Limited</Text>
                            <Text>Office No. 507, 5th Floor, Palm Spring Center</Text>
                            <Text>Malad Link Road, Malad (W)</Text>
                            <Text>Mumbai - 400064</Text>
                            <Text>GSTIN/UIN: 27AAVCA1450E1ZP</Text>
                            {/* <Text>State Name: Maharashtra, Code: 27</Text> */}
                            <Text>Email: akmittinnovations@gmail.com</Text>
                        </View>
                        <View style={styles.col}>
                            <Text>Invoice No: {invoiceNumber}</Text>
                            <Text>Dated: {new Date().toLocaleDateString()}</Text>
                            {/* <Text>State: Maharashtra, Code: 27</Text> */}
                            <Text>Terms of Delivery: 7 DAYS AFTER PAYMENT</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={styles.bold}>Buyer (Bill to): {activeUserData.fullName}</Text>
                            {/* <Text>{activeUserData.fullName}</Text> */}
                            {/* <Text>State Name: Maharashtra, Code: 27</Text> */}
                        </View>
                        {/* <View style={styles.col}>
                            <Text style={styles.bold}>Consignee (Ship to):</Text>
                            <Text>Consignee Name</Text>
                            <Text>State Name: Maharashtra, Code: 27</Text>
                        </View> */}
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableColHeader}>Sl No.</Text>
                        <Text style={styles.tableColHeader}>Description of Goods</Text>
                        {/* <Text style={styles.tableColHeader}>SAC</Text> */}
                        <Text style={styles.tableColHeader}>Quantity</Text>
                        <Text style={styles.tableColHeader}>Rate</Text>
                        <Text style={styles.tableColHeader}>Amount</Text>
                    </View>
                    {invoiceData && invoiceData.length > 0 && (
                        renderList()
                    )}
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}>Output CGST 9%</Text>
                        {/* <Text style={styles.tableCol}></Text> */}
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}>{cgst}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}>Output SGST 9%</Text>
                        {/* <Text style={styles.tableCol}></Text> */}
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}>{sgst}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}>Total</Text>
                        {/* <Text style={styles.tableCol}></Text> */}
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}></Text>
                        <Text style={styles.tableCol}>{grandTotal}</Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text>Amount Chargeable (in words): {number2words(grandTotal)}</Text>
                </View>

                <View style={styles.section}>
                    {/* <Text style={styles.bold}>Tax Amount (in words): INR</Text> */}
                    <View style={styles.bankDetails}>
                        <Text style={styles.bold}>Company's Bank Details:</Text>
                        <Text>Bank Name: HDFC Bank</Text>
                        <Text>A/C No: 50200072355067</Text>
                        <Text>Branch & IFSC Code: NARIMAN POINT MUMBAI BRANCH | HDFC0000001</Text>
                    </View>
                </View>

                <Text style={styles.footer}>This is a Computer Generated Invoice</Text>
            </Page>

        </Document>
    )
};

export default Invoice;

// Define the styles
const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        lineHeight: 1.5,
    },
    header: {
        textAlign: 'center',
        marginBottom: 20,
        fontWeight: 'bold',
    },
    section: {
        marginBottom: 10,
        paddingBottom: 10,
        borderBottom: '1px solid black',
    },
    row: {
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        marginRight: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
    table: {
        display: 'table',
        width: 'auto',
        marginBottom: 10,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#000',
        backgroundColor: '#f0f0f0',
        padding: 5,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tableCol: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#000',
        padding: 5,
        textAlign: 'center',
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10,
    },
    footer: {
        marginTop: 10,
        textAlign: 'center',
        fontSize: 8,
    },
    bankDetails: {
        marginTop: 10,
    },
    textRight: {
        textAlign: 'right',
    },
});